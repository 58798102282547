<template>
  <div class="ConnectedAccountRefreshPage">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CCard>
      <CCardBody>
        <CRow>
          <CCol class="text-center">
            <h1 style="font-size: 26px;"><strong>⚠️ Une erreur s'est produite</strong></h1>
          </CCol>
        </CRow>

        <CRow class="mt-3" style="font-size: 18px;">
          <CCol class="text-center">
            <p>
              <strong>Attention, une erreur s'est produite lors de la validation de votre compte !</strong>
            </p>
            <p>
              Votre lien a peut-être expiré ou alors votre compte a été rejeté. Veuillez retourner à l'accueil, réessayer et, les cas échéant, nous contacter.
            </p>
          </CCol>
        </CRow>

        <CRow>
          <CCol>
            <CButton class="mt-3" block shape="pill" color="outline-primary" size="lg" @click="$router.push('/')">
              Retour à l'accueil
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'ConnectedAccountRefreshPage',
  components: {
    Loading,
  },
  data: function () {
    return {
      isLoading: true,
    }
  },
  created: function() {
    setTimeout(() => { this.isLoading = false }, 2000);
  },
}
</script>
